import axios from "axios";

export const siteUrl = "https://api.note4.net";
export const apiURL = siteUrl + "/api";

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length !== 2) {
    return null;
  }

  return parts.pop().split(";").shift();
};

const initAxios = () => {
  return axios.create({
    baseURL: apiURL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getCookie("userToken")}`,
    },
    timeout: 4000,
  });
};

export const get = (url, user_id = null) => {
  const axios = initAxios();
  const currentURL = user_id ? "?user=" + user_id : "";
  return axios.get(url + currentURL);
};

export const post = (url, params = null, user_id = null) => {
  const axios = initAxios();
  return axios.post(url, params);
};

export const destroy = (url, params = null, user_id = null) => {
  const axios = initAxios();
  return axios.delete(url, params);
};

export const redirectAfterLogin = "/dashboard";
